<template>
  <div class="p-3 col-md-5">
    <div id="messages" class="conteiner-messages" @scroll="cancel_scroll">
      <div v-if="messages" class="d-flex flex-column">
        <div
          v-for="(item, idx) in messages"
          :key="idx + 'mes'"
          class="d-flex flex-row "
          :class="{
            'justify-content-end': item.uid === USER_INFO.uid
          }"
        >
          <div
            class="m-1 one-message-container d-flex flex-row"
            :class="{
              'no-sender-color': !(item.uid === USER_INFO.uid)
            }"
          >
            <div class="m-1">
              <div class="small-text d-inline">{{ `${item.user} ` }}</div>
              <div class="small-text d-inline">{{ item.date_send_normal }}</div>
              <div class="message-text">{{ item.message }}</div>
            </div>
            <div class="m-1">
              <button
                class="btn btn-sm btn-outline-Secondary shadow-none"
                @click="ws_delete(item.id)"
              >
                <i class="bi bi-trash"></i>
              </button>
            </div>
          </div>
        </div>
      </div>
      <div id="mmm"></div>
    </div>
    <div>
      <textarea
        class="form-control form-control-sm input-form shadow-none"
        rows="1"
        spellcheck="true"
        v-model.trim="message"
        @click="ws_connect()"
        ref="text"
      ></textarea>
    </div>
    <div>
      <button
        class="btn btn-sm btn-outline-success shadow-none mt-1"
        @click="ws_send()"
      >
        отправить
      </button>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "NewChat",
  props: ["chat_name", "chat_id_name", "id"],
  computed: {
    ...mapGetters(["USER_INFO"])
  },
  data: function() {
    return {
      messages: null,
      message: "",
      scroll: 0,
      ws: null,
      count: 0,
      send_message: false
    };
  },
  created() {
    this.ws = new WebSocket(`wss://sc-kalibr.ru:8000/`);
    this.ws.onopen = () => this.ws_connect();
    this.ws.onmessage = e => {
      const data = JSON.parse(e.data);
      this.messages = data;
      const elem = document.getElementById("mmm");
      elem.style.scrollSnapAlign = "end";
    };
  },
  mounted() {},
  methods: {
    ws_send() {
      this.ws.send(
        JSON.stringify({
          id_user: this.USER_INFO.uid,
          message: this.message,
          type: "send",
          chat_name: this.chat_name,
          chat_id_name: this.chat_id_name,
          chat_id: this.id
        })
      );
      this.ws_connect();
      this.message = "";
      this.$refs.text.focus();
    },
    ws_delete(id) {
      this.ws.send(
        JSON.stringify({
          id_user: this.USER_INFO.uid,
          type: "del",
          id,
          chat_name: this.chat_name,
          chat_id_name: this.chat_id_name,
          chat_id: this.id
        })
      );
      this.ws_connect();
    },
    ws_connect() {
      this.ws.send(
        JSON.stringify({
          id_user: this.USER_INFO.uid,
          chat_name: this.chat_name,
          chat_id_name: this.chat_id_name,
          chat_id: this.id
        })
      );
    },
    cancel_scroll() {
      this.scroll++;
      if (this.scroll > 2) {
        const elem = document.getElementById("mmm");
        elem.style.scrollSnapAlign = "none";
        this.scroll = 0;
      }
    },
    audio() {
      const audio3 = new Audio(require("@/sounds/whatsapp_iphone.mp3"));
      audio3.autoplay = true; // Автоматически запускаем
    }
  }
};
</script>
<style scoped>
#messages {
  overflow-y: auto;
  overflow-x: hidden;
}
.conteiner-messages {
  height: 300px;
  scroll-snap-type: y mandatory;
  background-color: #efeae2;
}
#mmm {
  scroll-snap-align: end;
}
.one-message-container {
  box-shadow: 0 1px 0.5px rgba(11, 20, 26, 0.13);
  background-color: #d9fdd3;
  border-radius: 7.5px;
  padding: 6px 7px 8px 9px;
  max-width: 80%;
  overflow-wrap: break-word;
}
.no-sender-color {
  background-color: #fff;
}
.small-text {
  font-size: 0.7em;
  color: #8d8d8d;
}
.message-text {
  font-size: 0.8em;
}
</style>
