import { render, staticRenderFns } from "./NewChat.vue?vue&type=template&id=565eb06c&scoped=true&"
import script from "./NewChat.vue?vue&type=script&lang=js&"
export * from "./NewChat.vue?vue&type=script&lang=js&"
import style0 from "./NewChat.vue?vue&type=style&index=0&id=565eb06c&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "565eb06c",
  null
  
)

export default component.exports