<template>
  <div>
    <div
      v-if="USER_INFO.status === 'admin' && USER_INFO.gruppa === 'super'"
      class="wrapper"
    >
      <button
        class="back btn btn-sm btn-outline-secondary shadow-none"
        @click="back"
      >
        <i class="bi bi-arrow-left"></i> назад
      </button>
      <button class="btn btn-sm shadow-none" :class="cla" @click="close">
        {{ closeButtonTitle }}
      </button>
    </div>
    <div v-else></div>
    <div v-if="USER_INFO.status === 'admin'">
      <NewChat chat_name="new_chat_ws" chat_id_name="chat_id" id="1" />
    </div>
    <div v-else></div>
  </div>
</template>
<script>
import { mapMutations, mapGetters } from "vuex";
import { HTTPGET } from "@/api";
import NewChat from "../../../components/newChat/NewChat.vue";
export default {
  name: "Kabinet",
  components: {
    NewChat
  },
  data: () => ({
    closeButtonTitle: "",
    cla: ""
  }),
  computed: {
    ...mapGetters(["CLOSESITE", "USER_INFO"])
  },
  methods: {
    ...mapMutations(["closeSite"]),
    close() {
      if (this.CLOSESITE) {
        this.closeSite(false);
        this.closeButtonTitle = "закрыть доступ на сайт";
        this.cla = "btn-outline-danger";
      } else {
        this.closeSite(true);
        this.closeButtonTitle = "открыть доступ на сайт";
        this.cla = "btn-outline-success";
      }
      HTTPGET({
        url: "admin/close_site.php",
        params: { close: this.CLOSESITE }
      });
    },
    back() {
      if (this.GET_PATH_FROM === "/login") {
        this.$router.push("/home");
      } else {
        this.$router.go(-1);
      }
    }
  },
  mounted() {
    if (this.CLOSESITE) {
      this.closeButtonTitle = "открыть доступ на сайт";
      this.cla = "btn-outline-success";
    } else {
      this.closeButtonTitle = "закрыть доступ на сайт";
      this.cla = "btn-outline-danger";
    }
  }
};
</script>
<style lang="scss" scoped>
.wrapper {
  padding: 10px;
}
button {
  margin-right: 10px;
}
</style>
